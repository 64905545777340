import { render, staticRenderFns } from "./indexListaDisciplina.vue?vue&type=template&id=4df24cf0&scoped=true&"
import script from "./indexListaDisciplina.vue?vue&type=script&lang=js&"
export * from "./indexListaDisciplina.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df24cf0",
  null
  
)

export default component.exports